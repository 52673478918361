<template>
	<div class="floor-style-2">
		<div class="head-wrap" v-if="data.value.title.value.text">
			<div class="head-left">
				<h2 v-if="current_lang"><router-link :to="{ path: data.value.title.value.link.url, query: { lang: current_lang } }" :title="data.value.title.value.text">{{ data.value.title.value.text }}</router-link></h2>
				<h2 v-else><router-link :to="data.value.title.value.link.url" :title="data.value.title.value.text">{{ data.value.title.value.text }}</router-link></h2>
				<span>{{ data.value.subTitle.value.text }}</span>
			</div>
			<div class="head-right" v-if="current_lang"><router-link :to="{ path: data.value.title.value.link.url, query: { lang: current_lang } }" :title="data.value.title.value.text">{{$lang('common.more')}} > </router-link></div>
			<div class="head-right" v-else><router-link :to="data.value.title.value.link.url" :title="data.value.title.value.text">{{$lang('common.more')}} > </router-link></div>
		</div>
		<div class="body-wrap">
			<ul class="goods-list">
				<li style="position: relative;" v-for="(item, index) in data.value.goodsList.value.list" :key="index" :title="item.goods_name" @click="goSku(item.goods_id)">
					<!-- 自定义标签 -->
					<div class="goods-diy diy-discount" v-if="item.price_label"><span>{{item.price_label}}</span></div>
					<div class="goods-diy diy-stock" :style="{background:item.label_color}" v-if="item.stock_label"><span>{{item.stock_label}}</span></div>
					<div class="goods-left-diy diy-own" v-if="item.own_label">
						<span><i class="iconfont icon-sandian"></i>{{item.own_label}}</span>
					</div>
					<div class="img-wrap"><img alt="商品图片" v-lazy="$img(item.goods_image.split(',')[0], {size: 'mid'})" @error="imageError(index)" /></div>
					<h3 v-if="current_lang"><router-link :to="'/product-' + item.goods_id + '.html?lang='+current_lang" :title="item.goods_name" target="_blank">{{ item.goods_name }}</router-link></h3>
					<h3 v-else><router-link :to="'/product-' + item.goods_id + '.html'" :title="item.goods_name" target="_blank">{{ item.goods_name }}</router-link></h3>
					<p class="desc">{{ item.introduction }}</p>
					<p class="comment-wrap" v-if="item.evaluate > 0">
						<el-rate v-model="item.evaluate_rate" disabled></el-rate>
                  		<span class="num">{{ item.evaluate }} {{$lang('common.evaluate_num')}}</span>
					</p>
					<p class="comment-wrap" v-else></p>
					<p class="price">
						<span class="num">{{ siteInfo.price_unit + item.discount_price }}</span>
						<del>{{ siteInfo.price_unit + item.market_price }}</del>
					</p>
				</li>
			</ul>
		</div>
		<div class="bottom-wrap" v-if="data.value.bottomImg.value.url">
			<img v-lazy="$img(data.value.bottomImg.value.url)" @click="$router.pushToTab(data.value.bottomImg.value.link.url)" />
		</div>
		<div class="bottom-wrap" v-if="data.value.bottomImg2.value.url">
			<img v-lazy="$img(data.value.bottomImg2.value.url)" @click="$router.pushToTab(data.value.bottomImg2.value.link.url)" />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'floor-style-2',
	props: {
		data: {
			type: Object
		}
	},
	data() {
		return {
			current_lang: '',
		};
	},
	created() {
		this.current_lang = this.$route.query.lang || ""
	},
	computed: {
		...mapGetters(['defaultGoodsImage','siteInfo'])
	},
	methods: {
		goSku(skuId) {
			this.$router.pushToTab('/product-' + skuId + '.html');
		},
		imageError(index) {
			this.data.value.goodsList.value.list[index].goods_image = this.defaultGoodsImage;
		}
	}
};
</script>

<style scoped>
	.comment-wrap /deep/ .el-rate__icon {
		margin-right: 0px;
	 }
</style>

<style lang="scss">
.floor-style-2 {
	margin-top: 10px;
	.head-wrap {
		text-align: center;
		border-bottom:1px solid #F54F3D;
		margin-bottom: 10px;
		display: flex;
		.head-left {
			display: flex;
			width: 50%;
			span {
				margin-left: 10px;
				line-height: 38px;
			}
		}
		.head-right {
			width: 48%;
			line-height: 38px;
			text-align: right;
		}
		p {
			color: #b0b0b0;
			padding: 0 10px;
			font-size: 14px;
			cursor: pointer;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin-bottom: 20px;
			position: relative;
			span {
				right: 0px;
				color: #333;
				position: absolute;
			}
		}
		
	}
	.body-wrap {
		.goods-list {
			display: flex;
			flex-wrap: wrap;
			li {
				width: 15.5%;
				margin-left: 15px;
				margin-bottom: 15px;
				background: #fff;
				cursor: pointer;
				padding: 10px 0;
				transition: all 0.2s linear;
				&:nth-child(6n + 1) {
					margin-left: 0;
				}
				&:hover {
					z-index: 2;
					-webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					-webkit-transform: translate3d(0, -2px, 0);
					transform: translate3d(0, -2px, 0);
				}
				.img-wrap {
					width: 185px;
					height: 185px;
					margin: 0 auto 18px;
					text-align: center;
					line-height: 160px;
					img {
						max-width: 100%;
						max-height: 100%;
					}
				}
				h3 {
					font-size: 12px;
					text-align: left;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					margin: 5px 15px;
				}
				.desc {
					margin: 0 15px 10px;
					height: 20px;
					font-size: 12px;
					color: #b0b0b0;
					text-align: left;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
				.comment-wrap {
					padding: 0px 10px;
					display: flex;
					align-items: center;
					height: 20px;
					.num span{
						padding-left: 6px;
						color: $base-color;
						font-size: $ns-font-size-base;
					}
				}
				.price {
					margin: 0 15px 14px;
					text-align: left;
					font-size: $ns-font-size-base;
					color: $base-color;
					del {
						margin-left: 0.5em;
						color: #b0b0b0;
					}
				}
			}
		}
	}
	.bottom-wrap {
		margin-top: 10px;
		width: $width;
		height: 252px;
		cursor: pointer;
		overflow: hidden;
		img {
			max-width: 100%;
		}
	}
}
</style>
