<template>
	<div>
		<!-- 首页固定区 -->
		<div class="index-wrap" :style="{ background: backgroundColor }" v-if="adList.length">
			<div class="index">
				<div class="banner">
					<el-carousel height="400px" arrow="hover" v-loading="loadingAd" @change="handleChange">
						<el-carousel-item v-for="item in adList" :key="item.adv_id">
							<el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" />
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>

		<div class="content">
			<!-- 售后服务 -->
			<div class="footer-top" v-if="shopServiceList.length > 0">
				<ul class="service">
					<li v-for="(item, index) in shopServiceList" :key="index">
						<div class="service-left">
							<el-image :src="$img(item.icon['imageUrl'])" :alt="item.name" fit="cover" />
						</div>
						<div class="service-right">
							<p>{{ item.service_name }}</p>
							<p class="desc">{{ item.desc }}</p>
						</div>
					</li>
				</ul>
			</div>

			<!-- 领券中心 -->
			<div class="content-div" v-if="addonIsExit.coupon == 1 && couponList.length > 0">
				<div class="coupon">
					<div class="coupon-title">
						<p class="coupon-font">{{$lang("common.coupons")}}</p>
						<p class="coupon-en">coupon</p>
						<p class="coupon-more" @click="$router.push('/coupon')">
							<span>{{$lang("common.more")}}</span>
							<i class="iconfont iconarrow-right"></i>
						</p>
					</div>

					<ul class="coupon-list">
						<li v-for="(item, index) in couponList" :key="index">
							<p class="coupon-price ns-text-color" v-if="item.type == 'reward'" :class="{ disabled: item.useState == 2 }">
								{{ siteInfo.price_unit }}
								<span>{{parseInt(item.money) }}</span>
							</p>
							<p class="coupon-term">
								<span>{{ item.coupon_name }}</span>
							</p>
							<p class="coupon-receive ns-text-color" @click="couponTap(item, index)">
								<span v-if="item.useState == 0">{{$lang("common.get")}}</span>
								<span v-else>去使用</span>
								<i class="iconfont iconarrow-right"></i>
							</p>
						</li>
					</ul>
				</div>
			</div>

			<!-- 广告 -->

			<!-- 限时秒杀 -->
			<div class="content-div" v-if="addonIsExit.seckill == 1 && listData.length > 0">
				<div class="seckill-wrap">
					<div class="seckill-time">
						<div class="seckill-time-left">
							<i class="iconfont iconmiaosha1 ns-text-color"></i>
							<span class="seckill-time-title ns-text-color">{{$lang('limited_spike')}}</span>
							<span>{{ seckillText }}</span>
							<count-down
								class="count-down"
								v-on:start_callback="countDownS_cb()"
								v-on:end_callback="countDownE_cb()"
								:currentTime="seckillTimeMachine.currentTime"
								:startTime="seckillTimeMachine.startTime"
								:endTime="seckillTimeMachine.endTime"
								:dayTxt="'：'"
								:hourTxt="'：'"
								:minutesTxt="'：'"
								:secondsTxt="''"
							></count-down>
						</div>
						<div class="seckill-time-right" @click="$router.push('/seckill')">
							<span><router-link :to="'/seckill'" :title="$lang('limited_spike')">{{$lang('common.more')}}</router-link></span>
							<i class="iconfont iconarrow-right"></i>
						</div>
					</div>
					<div class="seckill-content">
						<vue-seamless-scroll :data="listData" :class-option="optionLeft" class="seamless-warp2">
							<ul class="item" :style="{ width: 250 * listData.length + 'px' }">
								<li v-for="(item, index) in listData" :key="index">
									<div class="seckill-goods" @click="$router.pushToTab('/seckill/item/' + item.id)">
										<div class="seckill-goods-img"><img :src="$img(item.sku_image)" @error="imageError(index)" /></div>
										<p>{{ item.sku_name }}</p>
										<div class="seckill-price-wrap">
											<p class="ns-text-color">
												{{ siteInfo.price_unit }}
												<span>{{ item.seckill_price }}</span>
											</p>
											<p class="primary-price">{{ siteInfo.price_unit + item.price }}</p>
										</div>
									</div>
								</li>
							</ul>
						</vue-seamless-scroll>
					</div>
				</div>
			</div>

			<!-- 楼层区 -->
			<div class="content-div">
				<div class="floor">
					<div v-for="(item, index) in floorList" :key="index" class="floor_item">
						<floor-style-1 v-if="item.block_name == 'floor-style-1'" :data="item" />
						<floor-style-2 v-if="item.block_name == 'floor-style-2'" :data="item" />
						<floor-style-3 v-if="item.block_name == 'floor-style-3'" :data="item" />
					</div>
				</div>
			</div>
			
			<!-- 浮层区 -->
			<div class="floatLayer-wrap" v-if="floatLayer.is_show_type">
				<div class="floatLayer">
					<div class="img-wrap">
						<img :src="$img(floatLayer.img_url)" @click="$router.pushToTab(floatLayer.link.url)" />
					</div>
					<i class="el-icon-circle-close" @click="closeFloat"></i>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import floorStyle1 from './components/floor-style-1.vue';
import floorStyle2 from './components/floor-style-2.vue';
import floorStyle3 from './components/floor-style-3.vue';
import index from './_index/index.js';

export default {
	name: 'index',
	components: { floorStyle1,floorStyle2,floorStyle3 },
	mixins: [index]
};
</script>

<style lang="scss" scoped>
	@import './_index/index.scss';
</style>

<style lang="scss" scoped>
	.count-down {
		span {
			display: inline-block;
			width: 22px;
			height: 22px;
			line-height: 22px;
			text-align: center;
			background: #383838;
			color: #ffffff;
			border-radius: 2px;
		}
	}
	.more{
		width: 1200px;
		margin: auto;
		display: flex;
		justify-content: flex-end;
		a{
			color:#ff547b
		}
	}
	.goods-info {
		width:1200px;
	  margin:5px auto 40px;
	  display: flex;
	  flex-wrap: wrap;
	  .item {
	    width: 202px;
	    margin: 10px 20px 0 0;
	    border: 1px solid #eeeeee;
	    padding: 10px;
	    position: relative;
	    &:nth-child(5 n) {
	      margin-right: initial !important;
	    }
	    &:hover {
	      border: 1px solid $base-color;
	    }
	    .img-wrap {
	      width: 198px;
	      height: 198px;
	      cursor: pointer;
	    }
	    .goods-name {
	      overflow: hidden;
	      text-overflow: ellipsis;
	      white-space: nowrap;
	      cursor: pointer;
	      &:hover {
	        color: $base-color;
	      }
	    }
	    .price-wrap {
	      display: flex;
	      align-items: center;
	      .price {
	        display: flex;
	        color: $base-color;
	        font-size: $ns-font-size-lg;
	        p {
	          font-size: $ns-font-size-base;
	          display: flex;
	          align-items: flex-end;
	        }
	      }
	      .market-price {
	        color: #838383;
	        text-decoration: line-through;
	        margin-left: 10px;
	      }
	    }
	    .sale-num {
	      display: flex;
	      color: #838383;
	      p {
	        color: #4759a8;
	      }
	    }
	    .saling {
	      display: flex;
	      font-size: $ns-font-size-sm;
	      line-height: 1;
	      .free-shipping {
	        background: $base-color;
	        color: #ffffff;
	        padding: 3px 4px;
	        border-radius: 2px;
	        margin-right: 5px;
	      }
	      .promotion-type {
	        color: $base-color;
	        border: 1px solid $base-color;
	        display: flex;
	        align-items: center;
	        padding: 1px;
	      }
	    }
	  }
	}
	
	.footer-subscription{
		background-color: #FFF;	
		// width: $width;
		margin: 0 auto;
		margin-top: 20px;
		padding-top: 50px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		p{
			font-size: 22px;
			font-weight: 600;
			padding: 10px 0;
		}
		
		.submit{
			display: flex;
			// justify-content: center;
			align-items: center;
		}
		
		input{
			width: 300px;
			height: 40px;
			background:none;  
			outline:none;  
			border:2px solid #ccc;
			padding-left: 15px;
			// margin-bottom: 2px;
			&::-webkit-input-placeholder{
				color: #444;
				font-size:20px;
			}
			
			&::-moz-placeholder{
				color: #444;
				font-size:15px;
			}
		}
		
		button{
			// padding: 0 10px;
			margin-left: 6px;
			width: 100px;
			height: 45px;
			// padding-bottom: 5px;
			margin-bottom: 1px;
			background-color: $base-color;
			color: #fff;
			font-size: 16px;
			font-weight: 600;
			border: 0;
			cursor: pointer;
			&:hover{
				background-color: #74a32f;
			}
		}
		
	}
</style>
