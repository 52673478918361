<template>
	<div class="floor-style-1">
		<div class="head-wrap" v-if="data.value.title.value.text">
			<h2 @click="$router.pushToTab(data.value.title.value.link.url)" :style="{ color: data.value.title.value.color }">{{ data.value.title.value.text }}</h2>
			<p @click="$router.pushToTab(data.value.title.value.link.url)" :style="{ color: data.value.subTitle.value.color }">
				{{ data.value.subTitle.value.text }}
				<span>{{$lang('common.more')}} > </span>
			</p>
		</div>
		<div class="body-wrap">
			<div class="left-wrap" v-if="data.value.leftImg.value.url">
				<img v-lazy="$img(data.value.leftImg.value.url)" @click="$router.pushToTab(data.value.leftImg.value.link.url)" />
			</div>
			<ul class="goods-list">
				<li style="position: relative;" v-for="(item, index) in data.value.goodsList.value.list" :key="index" :title="item.goods_name" @click="goSku(item.goods_id)">
					<!-- 自定义标签 -->
					<div class="goods-diy diy-discount" v-if="item.price_label"><span>{{item.price_label}}</span></div>
					<div class="goods-diy diy-stock" :style="{background:item.label_color}" v-if="item.stock_label"><span>{{item.stock_label}}</span></div>
					<div class="goods-left-diy diy-own" v-if="item.own_label">
						<span><i class="iconfont icon-sandian"></i>{{item.own_label}}</span>
					</div>
					<div class="img-wrap"><img alt="商品图片" v-lazy="$img(item.goods_image.split(',')[0], {size: 'mid'})" @error="imageError(index)" /></div>
					<h3><router-link :to="'/product-' + item.goods_id + '.html'" :title="item.goods_name" target="_blank">{{ item.goods_name }}</router-link></h3>
					<p class="desc">{{ item.introduction }}</p>
					<p class="comment-wrap" v-if="item.evaluate > 0">
						<el-rate v-model="item.evaluate_rate" disabled></el-rate>
                  		<span class="num">{{ item.evaluate }} {{$lang('common.evaluate_num')}}</span>
					</p>
					<p class="comment-wrap" v-else></p>
					<p class="price">
						<span class="num">{{ siteInfo.price_unit + item.discount_price }}</span>
						<del>{{ siteInfo.price_unit + item.market_price }}</del>
					</p>
				</li>
			</ul>
		</div>
		<div class="bottom-wrap" v-if="data.value.bottomImg.value.url">
			<img v-lazy="$img(data.value.bottomImg.value.url)" @click="$router.pushToTab(data.value.bottomImg.value.link.url)" />
		</div>
		<div class="bottom-wrap" v-if="data.value.bottomImg2.value.url">
			<img v-lazy="$img(data.value.bottomImg2.value.url)" @click="$router.pushToTab(data.value.bottomImg2.value.link.url)" />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'floor-style-1',
	props: {
		data: {
			type: Object
		}
	},
	data() {
		return {};
	},
	created() {},
	computed: {
		...mapGetters(['defaultGoodsImage', "siteInfo"])
	},
	methods: {
		goSku(skuId) {
			this.$router.pushToTab('/product-' + skuId + '.html');
		},
		imageError(index) {
			this.data.value.goodsList.value.list[index].goods_image = this.defaultGoodsImage;
		}
	}
};
</script>

<style scoped>
	.comment-wrap /deep/ .el-rate__icon {
		margin-right: 0px;
	 }
</style>

<style lang="scss">
.floor-style-1 {
	.head-wrap h2 {
		line-height: 30px;
		color: #333;
		padding: 10px;
		font-size: 18px;
		cursor: pointer;
		width: 95%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.body-wrap {
		.left-wrap {
			float: left;
			width: 234px;
			height: 614px;
			cursor: pointer;
			transition: all 0.2s linear;
			&:hover {
				z-index: 2;
				-webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
				box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
				-webkit-transform: translate3d(0, -2px, 0);
				transform: translate3d(0, -2px, 0);
			}
			img {
				width: 100%;
				height: 100%;
				cursor: pointer;
			}
		}
		.goods-list {
			margin-left: 235px;
			display: flex;
			flex-wrap: wrap;
			li {
				width: 20%;
				margin-left: 13px;
				margin-bottom: 20px;
				background: #fff;
				cursor: pointer;
				padding: 10px 0;
				position: relative;
				&:hover::after{
					content:'';
					position: absolute;
					top:0;
					left:0;
					height:100%;
					width:100%;
					z-index: 2;
					border: 1px solid $base-color;
				}
				.img-wrap {
					width: 185px;
					height: 185px;
					margin: 0 auto 18px;
					text-align: center;
					line-height: 160px;
					img {
						max-width: 100%;
						max-height: 100%;
					}
				}
				h3 {
					font-size: 12px;
					text-align: left;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					margin: 5px 15px;
				}
				.desc {
					margin: 0 15px;
					height: 20px;
					font-size: 12px;
					color: #b0b0b0;
					text-align: left;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
				.comment-wrap {
					padding: 0px 10px;
					display: flex;
					align-items: center;
					height: 20px;
					.num span{
						padding-left: 6px;
						color: $base-color;
						font-size: $ns-font-size-base;
					}
				}
				.price {
					margin: 0 15px 14px;
					text-align: left;
					font-size: $ns-font-size-base;
					color: $base-color;
					del {
						margin-left: 0.5em;
						color: #b0b0b0;
					}
				}
			}
		}
	}
	.bottom-wrap {
		margin-top: 10px;
		width: $width;
		height: 252px;
		cursor: pointer;
		overflow: hidden;
		img {
			max-width: 100%;
		}
	}
}

.goods-diy{
	position: absolute;
	right: 0;
	line-height: 22px;
	padding: 2px 5px;
	height: 22px;
	min-width: 35px;
	z-index: 1;
	// border-top-left-radius: 15px;
	// border-bottom-left-radius: 15px;
	text-align: center;
	color: #fff;
}
.goods-left-diy {
	position: absolute;
	left: 0;
	top: 0;
	line-height: 25px;
	padding: 2px 5px 2px 2px;
	height: 25px;
	width: 50px;
	z-index: 1;
	border-radius: 0px 0px 8px;
	text-align: center;
	color: #fff;
}
// 折扣标签
.diy-discount {	
	top: 10px;
	background-color: #F54F3D;
	
	span{
		letter-spacing:1px;
		// padding-left: 4px;
		font-size: 12px;
	}
}

.diy-stock {	
	top: 50px;
	background-color: #75D69C;
	
	span{
		letter-spacing:1px;
		padding-left: 2px;
		font-size: 12px;
	}
}
.diy-own {
	background-color: #EA4D1A;
	
	span{
		letter-spacing:1px;
		font-size: 14px;
		.iconfont {
			font-size: 14px;
		}
	}
}
</style>
